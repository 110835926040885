.centerAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--seccl-spacing-large);
  margin: 6rem var(--seccl-spacing-xxxlarge) 0;
}

.title {
  font-weight: var(--seccl-font-weight-regular);
  font-size: var(--seccl-font-size-xxxlarge);
  text-align: center;
}

.button {
  font-weight: var(--seccl-font-weight-regular);
  padding: 0 var(--seccl-spacing-xxxxlarge);
}

@media (width <= 768px) {
  .button {
    width: 100%;
  }
}
