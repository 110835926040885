.container {
  background-color: var(--seccl-color-brand-primary);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--seccl-spacing-medium);
}

.logo {
  --logo-height: 55px;

  height: var(--logo-height);
  max-width: 200px;
  object-fit: contain;
  width: 100%;
  margin-bottom: var(--seccl-spacing-xxxlarge);
}

.signin {
  --container-max-width: 400px;
  --container-min-height: 400px;
  --container-padding: 100px var(--seccl-spacing-xxxlarge);

  max-width: var(--container-max-width);
  min-height: var(--container-min-height);
  width: 100%;
  padding: var(--container-padding);
  background-color: var(--seccl-color-white);
  border-radius: var(--seccl-border-radius-small);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--seccl-color-grey-2);
  box-shadow: 0 4px 24px 0 #1d17390a;

  h1 {
    margin: 0;
    font-size: var(--seccl-font-size-xlarge);
    font-weight: var(--seccl-font-weight-regular);
  }

  p {
    flex-grow: 1;
  }
}

.button {
  width: 100%;
  margin-top: var(--seccl-spacing-large);
}
